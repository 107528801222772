import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import { Link } from "@mui/material";
import { Box } from "@mui/material";
import { MUIStyledCommonProps, styled } from "@mui/system";
import { useLocation } from "react-router-dom";

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
  transform-origin: center;
  &:hover {
    transform: scale(1.05);
  }
`;

export default function Logo({ sx }: MUIStyledCommonProps) {
  const location = useLocation();
  const clickableLogo = location.pathname === "/";
  const { logo } = useGlobalConfig();
  return (
    <LogoContainer
      href={clickableLogo ? "/" : undefined}
      sx={{
        color: "black",
        fontSize: "1.5rem",
        fontWeight: "bold",
        pointerEvents: clickableLogo ? "auto" : "none",
        textDecoration: "none",
        ...sx,
      }}
    >
      <Box component="img" src={logo.src} />
    </LogoContainer>
  );
}
