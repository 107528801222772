import React, { createContext } from "react";

export interface IConverterConfig {
  palette: {
    dark: string;
    darkest: string;
  };
  homepage: {
    section: {
      stepsWithImages: {
        bgColor: string;
        color: string;
        homeImage12?: string;
        homeImage22?: string;
        homeImage23?: string;
      };
      security?: {
        imageFilter: string;
      };
    };
  };
}

export const ConverterConfigContext = createContext<
  IConverterConfig | undefined
>(undefined);

export default function ConverterConfigProvider({
  children,
  value = {
    palette: {
      dark: "#1A0F4E",
      darkest: "#07052d",
    },
    homepage: {
      section: {
        stepsWithImages: {
          bgColor: "primary.main",
          color: "white",
        },
      },
    },
  },
}: {
  value?: IConverterConfig;
  children: React.ReactNode;
}) {
  return (
    <ConverterConfigContext.Provider value={value}>
      {children}
    </ConverterConfigContext.Provider>
  );
}
