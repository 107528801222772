import CompanyInfo from "@cospex/client/components/CompanyInfo";
import LanguageSelect from "@cospex/client/components/LanguageSelect";
import MarketingEmail from "@cospex/client/components/MarketingEmail";
import Logo from "@cospex/client/converter/components/Logo";
import { useConverterConfig } from "@cospex/client/converter/hooks/useConverterConfig";
import useUploads from "@cospex/client/converter/hooks/useUploads";
import PaymentProcessorImg from "@cospex/client/converter/img/payment-processor.svg";
import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  Box,
  Container,
  Divider,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const { languages } = useGlobalConfig();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { palette } = useConverterConfig();
  const { removeAllUploads } = useUploads();
  const siteLinks = [
    { href: "/cookies", label: "footer-link-cookies" },
    { href: "/privacy", label: "footer-link-privacy" },
    { href: "/terms", label: "footer-link-terms" },
    { href: "/contact", label: "footer-link-contact" },
  ];

  const landingPageLinks = [
    {
      href: "/?from=pdf&to=word",
      label: "footer-link-convert-specific",
      params: { from: "PDF", to: "Word" },
    },
    {
      href: "/?from=pdf&to=excel",
      label: "footer-link-convert-specific",
      params: { from: "PDF", to: "Excel" },
    },
    {
      href: "/?from=jpg&to=png",
      label: "footer-link-convert-specific",
      params: { from: "JPG", to: "PNG" },
    },
    {
      href: "/?from=word&to=png",
      label: "footer-link-convert-specific",
      params: { from: "Word", to: "PNG" },
    },
    {
      href: "/?from=png&to=pdf",
      label: "footer-link-convert-specific",
      params: { from: "PNG", to: "PDF" },
    },
    {
      href: "/?from=png&to=word",
      label: "footer-link-convert-specific",
      params: { from: "PNG", to: "Word" },
    },
    {
      href: "/?from=jpg&to=pdf",
      label: "footer-link-convert-specific",
      params: { from: "JPG", to: "PDF" },
    },
    {
      href: "/?compressionFormat=pdf",
      label: "footer-link-compress-specific",
      params: { to: "PDF" },
    },
    {
      href: "/?compressionFormat=png",
      label: "footer-link-compress-specific",
      params: { to: "PNG" },
    },
    {
      href: "/?compressionFormat=jpg",
      label: "footer-link-compress-specific",
      params: { to: "JPG" },
    },
  ];

  const accountLinks = [
    { href: "/pricing", label: "footer-link-pricing" },
    { href: "/refunds", label: "footer-link-refunds" },
    { href: "/login", label: "footer-link-login" },
    { href: "/unsubscribe", label: "footer-link-unsubscribe" },
  ];

  return (
    <Box sx={{ backgroundColor: palette.darkest, pt: 8 }}>
      <Container
        sx={{
          ".MuiTypography-root": {
            color: "white",
          },
          ul: {
            listStyle: "none",
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 6,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
                gap: 2,
                mb: 4,
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              <Logo />
              {__APP_NAME__}
            </Box>

            <Typography
              variant="body2"
              sx={{ maxWidth: 300, pb: "1rem", color: "grey.500" }}
            >
              {t("footer-description")}
            </Typography>
            <LanguageSelect
              options={languages}
              sx={{
                backgroundColor: "white",
              }}
            />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 3,
            }}
          >
            <ul>
              {siteLinks.map((link, index) => (
                <li key={index}>
                  <Link target="_blank" href={link.href}>
                    {t(link.label)}
                  </Link>
                </li>
              ))}
              {landingPageLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    target="_blank"
                    href={link.href}
                    onClick={() => {
                      removeAllUploads();
                    }}
                  >
                    {t(link.label, link.params)}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 3,
            }}
          >
            <ul>
              {accountLinks.map((link, index) => (
                <li key={index}>
                  <Link target="_blank" href={link.href}>
                    {t(link.label)}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Box sx={{ mt: "2rem" }}>
            <Box
              component="img"
              sx={{
                maxWidth: 180,
                ...(i18n.language === "fr"
                  ? {
                      transform: "translateX(10px)",
                    }
                  : {
                      clipPath: "inset(0 calc(100% - 140px) 0 0)",
                      transform: "translateX(10px)",
                    }),
              }}
              src={PaymentProcessorImg}
            />
          </Box>
          <MarketingEmail />
        </Grid>
        <Divider light sx={{ mb: 1 }} />
        <Box
          sx={{
            display: {
              xs: "block",
              md: "flex",
            },
            alignItems: "center",
            textAlign: {
              xs: "center",
              md: "left",
            },
            justifyContent: {
              xs: "center",
              md: "space-between",
            },
            pb: 2,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              py: 2,
            }}
          >
            Copyright {" © "}
            {new Date().getFullYear()} {__APP_NAME__}{" "}
          </Typography>

          <CompanyInfo
            color="white"
            width={isDesktop ? 340 : 300}
            textAlign={isDesktop ? "right" : "center"}
          />
        </Box>
      </Container>
    </Box>
  );
}
