import { ConverterConfigContext } from "@cospex/client/converter/provider";
import { useContext } from "react";

export const useConverterConfig = () => {
  const context = useContext(ConverterConfigContext);
  if (!context) {
    throw new Error(
      "useConverterConfig must be used within a ConverterConfigProvider"
    );
  }
  return context;
};
